var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "container" }, [
    _c("div", { staticClass: "container__top" }, [
      _c(
        "div",
        { staticClass: "indexTabs" },
        [
          _c(
            "el-tabs",
            {
              staticClass: "marginLeft10",
              model: {
                value: _vm.tabs,
                callback: function ($$v) {
                  _vm.tabs = $$v
                },
                expression: "tabs",
              },
            },
            _vm._l(_vm.tapArray, function (tab, index) {
              return _c("el-tab-pane", {
                key: index,
                attrs: { label: tab.menuName, name: tab.component },
              })
            }),
            1
          ),
        ],
        1
      ),
    ]),
    _c(
      "div",
      { staticClass: "container__body bcWhite box-shadow" },
      [
        _vm.tabs === "goodsList" ? _c("GoodsList") : _vm._e(),
        _vm.tabs === "goodsSort" ? _c("GoodsSort") : _vm._e(),
        _vm.tabs === "goodsLabel" ? _c("GoodsLabel") : _vm._e(),
        _vm.tabs === "goodsBrand" ? _c("GoodsBrand") : _vm._e(),
        _vm.tabs === "goodsDictionary" ? _c("GoodsDictionary") : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }