var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "goodsSort scrollbar",
      staticStyle: { "padding-left": "10px", "padding-top": "10px" },
    },
    [
      _c(
        "el-form",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.showSearch,
              expression: "showSearch",
            },
          ],
          ref: "queryForm",
          attrs: { model: _vm.queryParams, size: "small", inline: true },
        },
        [
          _c(
            "el-form-item",
            [
              _c(
                "el-input",
                {
                  staticClass: "input-with-select w300",
                  staticStyle: { "margin-right": "300px" },
                  attrs: { placeholder: "分类名称", clearable: "" },
                  model: {
                    value: _vm.queryParams.ssort,
                    callback: function ($$v) {
                      _vm.$set(_vm.queryParams, "ssort", $$v)
                    },
                    expression: "queryParams.ssort",
                  },
                },
                [
                  _c("el-button", {
                    attrs: { slot: "append", icon: "el-icon-search" },
                    on: { click: _vm.handleQuery },
                    slot: "append",
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            [
              _c(
                "el-button",
                {
                  attrs: {
                    type: "primary",
                    plain: "",
                    icon: "el-icon-plus",
                    size: "mini",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.handleAdd()
                    },
                  },
                },
                [_vm._v("添加一级分类")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm.refreshTable
        ? _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              attrs: {
                data: _vm.sortList,
                "row-key": "sid",
                "default-expand-all": _vm.isExpandAll,
                "tree-props": {
                  children: "children",
                  hasChildren: "hasChildren",
                },
              },
            },
            [
              _c("el-table-column", {
                attrs: { prop: "ssort", label: "分类名称", width: "260" },
              }),
              _c("el-table-column", {
                attrs: { prop: "nindex", label: "排序", width: "200" },
              }),
              _c("el-table-column", {
                attrs: {
                  label: "操作",
                  align: "left",
                  "class-name": "small-padding fixed-width",
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c(
                            "el-button",
                            {
                              attrs: {
                                size: "mini",
                                type: "text",
                                icon: "el-icon-edit",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.handleUpdate(scope.row)
                                },
                              },
                            },
                            [_vm._v("修改")]
                          ),
                          _c(
                            "el-button",
                            {
                              attrs: {
                                size: "mini",
                                type: "text",
                                icon: "el-icon-plus",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.handleAdd(scope.row)
                                },
                              },
                            },
                            [_vm._v("新增")]
                          ),
                          scope.row.rsort != undefined
                            ? _c(
                                "el-button",
                                {
                                  staticStyle: { color: "red" },
                                  attrs: {
                                    size: "mini",
                                    type: "text",
                                    icon: "el-icon-delete",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.handleDelete(scope.row)
                                    },
                                  },
                                },
                                [_vm._v("删除")]
                              )
                            : _vm._e(),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  635591333
                ),
              }),
            ],
            1
          )
        : _vm._e(),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.title,
            visible: _vm.open,
            width: "600px",
            "append-to-body": "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.open = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "form",
              attrs: {
                model: _vm.form,
                rules: _vm.rules,
                "label-width": "90px",
              },
            },
            [
              _c(
                "el-row",
                [
                  _vm.form.rsort !== 0
                    ? _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "上级分类", prop: "rsort" } },
                            [
                              _c("el-cascader", {
                                staticClass: "custom-cas",
                                attrs: {
                                  value: _vm.form.rsort,
                                  options: _vm.sortOptions,
                                  props: {
                                    value: "sid",
                                    label: "ssort",
                                    checkStrictly: true,
                                  },
                                  placeholder: "选择上级分类",
                                },
                                on: { change: _vm.handleCascaderChange },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "分类名称", prop: "ssort" } },
                        [
                          _c("el-input", {
                            staticStyle: { width: "400px" },
                            attrs: { placeholder: "请输入分类名称" },
                            model: {
                              value: _vm.form.ssort,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "ssort", $$v)
                              },
                              expression: "form.ssort",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "显示排序", prop: "nindex" } },
                        [
                          _c("el-input-number", {
                            attrs: { "controls-position": "right", min: 0 },
                            model: {
                              value: _vm.form.nindex,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "nindex", $$v)
                              },
                              expression: "form.nindex",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.submitForm } },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }