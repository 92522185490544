<template>
  <!-- 商品主页 -->
  <div class="container">
    <div class="container__top">
      <div class="indexTabs">
        <el-tabs v-model="tabs" class="marginLeft10">
          <el-tab-pane v-for="(tab, index) in tapArray" :key="index" :label="tab.menuName" :name="tab.component" />
        </el-tabs>
      </div>
    </div>
    <div class="container__body bcWhite box-shadow">
      <GoodsList v-if="tabs === 'goodsList'" />
      <GoodsSort v-if="tabs === 'goodsSort'" />
      <GoodsLabel v-if="tabs === 'goodsLabel'" />
      <GoodsBrand v-if="tabs === 'goodsBrand'" />
      <GoodsDictionary v-if="tabs === 'goodsDictionary'" />
    </div>
  </div>
</template>

<script>
import GoodsList from "./goodsList.vue";
import GoodsLabel from "./goodsLabel.vue";
import GoodsBrand from "./goodsBrand.vue";
import GoodsDictionary from "./goodsDictionary.vue";
import store from "../../store";
import GoodsSort from "@/views/goods/goodsSort";

export default {
  name: "GoodsIndex",
  components: { GoodsSort, GoodsList, GoodsLabel, GoodsDictionary, GoodsBrand },
  data() {
    return {
      tabs: "",
      tapArray: "",
    };
  },
  created() {
    const nav = JSON.parse(JSON.stringify(store.getters.navs));
    const result = nav.find((item) => item.component === "goodsIndex");
    this.tapArray = result.children;
    this.tabs = this.tapArray[0].component;
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.container {
  height: 100%;
  display: flex;
  width: 100%;
  background-color: #f6f6f6;
  padding: 10px;
  flex-direction: column;
  overflow: hidden;
  z-index: 1;
  &__top {
    height: 70px;
    margin: 0 3px;
    margin-bottom: 10px;
  }
  &__body {
    height: 100%;
    flex: 1;
    margin: 0 3px;
    overflow: hidden;
  }
}
</style>
