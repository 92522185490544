<template>
  <!-- 货品信息 -->
  <div class="goodsList">
    <div class="goodsList__left scrollbar">
      <el-input v-model="filterText" class="wHP" placeholder="输入关键字进行过滤" clearable> </el-input>
      <el-tree ref="tree" show-checkbox class="filter-tree" :data="sortsData" :props="defaultProps" default-expand-all :filter-node-method="filterNode" @check-change="handleCheckChange" />
    </div>
    <div class="goodsList__right">
      <div class="flexGrow1 scrollbar">
        <div class="bcWhite margin10 padding10">
          <el-row class="marginBottom10">
            <el-col class="borderBottom paddingBottom10 marginBottom10">
              <span class="fs20 fWB">货品列表</span>
            </el-col>
            <el-row>
              <el-col :span="12">
                <el-input v-model="queryParams.searchValue" placeholder="货品名称/货品编号/SKU" class="input-with-select w400" clearable @change="handleQueryGoods">
                  <el-button slot="append" icon="el-icon-search" @click="handleQueryGoods"></el-button>
                </el-input>
              </el-col>
              <el-col :span="12" class="textRight">
                <!--                <el-button>+添加货品</el-button>-->
                <el-button @click="handleGoodsTemplate">下载模板</el-button>
                <el-button @click="upload.open = true">导入货品</el-button>
                <el-button @click="handleExport">导出货品</el-button>
              </el-col>
            </el-row>
          </el-row>
          <el-row style="height: 644px" class="scrollbar" :gutter="10">
            <el-col :span="8">
              <div class="h150">
                <el-button class="wHP hHP" @click="JumpAddInfo">+添加货品</el-button>
              </div>
            </el-col>
            <el-col v-for="(goods, index) in goodsData" :key="index" :span="8">
              <div class="marginBottom10">
                <el-card :body-style="{ padding: '0' }">
                  <el-row :gutter="10" class="margin10">
                    <el-col :span="5">
                      <div class="bg-purple" style="margin-top: 5px">
                        <el-image v-if="goods.cmasterUrl === undefined" class="custom-empty"
                          ><div slot="error" class="image-slot">
                            <i class="el-icon-picture-outline"></i></div
                        ></el-image>
                        <el-image v-if="goods.cmasterUrl" :src="goods.cmasterUrl" fit="fit" style="width: 61px; height: 38px"></el-image>
                      </div>
                      <div class="textCenter">零售价</div>
                      <div class="textCenter">{{ goods.nprice }}</div>
                    </el-col>
                    <el-col :span="19">
                      <div class="">
                        <el-link class="hideContent" :title="goods.cname" type="primary" style="font-weight: 800" @click="handleViewGoods(goods.sid, goods.rsort, sortsData)">{{ goods.cname }}</el-link>
                        <div>
                          <el-cascader :value="goods.rsort" :options="sortsData" :props="{ value: 'sid', label: 'ssort' }" separator="-" class="custom-cas-goods-list" placeholder="暂无数据" disabled @change="handleCascaderChange"></el-cascader>
                        </div>
                        <el-row>
                          <el-col>货品SKU: {{ goods.cbarcode }}</el-col>
                        </el-row>
                        <el-row>
                          <el-col>货品条码: {{ goods.csku }}</el-col>
                        </el-row>
                      </div>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col>
                      <el-button-group style="width: 100%">
                        <el-button style="width: 50%; color: #1482f0" @click="handleEditGoods(goods.sid)">编辑</el-button>
                        <el-button type="danger" style="width: 50%" @click="handleDeleteGoods(goods)">删除</el-button>
                      </el-button-group>
                    </el-col>
                  </el-row>
                </el-card>
              </div>
            </el-col>
          </el-row>
        </div>
      </div>
      <el-pagination class="paddingBottom20 marginRight20" :current-page="queryParams.pageNum" :page-sizes="[10, 20, 30, 50]" :page-size="queryParams.pageSize" layout="total, sizes, prev, pager, next, jumper" :total="total" @size-change="handleSizeChange" @current-change="handlePageChange"> </el-pagination>
    </div>

    <!--货品导入 -->
    <el-dialog :title="upload.title" :visible.sync="upload.open" width="400px" append-to-body>
      <el-upload ref="upload" :limit="1" accept=".xlsx, .xls" :headers="upload.headers" :action="upload.url + '?updateSupport=' + upload.updateSupport" :disabled="upload.isUploading" :on-progress="handleFileUploadProgress" :on-success="handleFileSuccess" :auto-upload="false" drag>
        <i class="el-icon-upload"></i>
        <div class="el-upload__text">点击上传</div>
        <div slot="tip" class="el-upload__tip text-center">
          <div slot="tip" class="el-upload__tip"><el-checkbox v-model="upload.updateSupport" /> 是否更新已经存在的货品数据</div>
        </div>
      </el-upload>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submitFileForm">确 定</el-button>
        <el-button @click="upload.open = false">取 消</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { deleteGoodsById, exportGoods, goodsList, goodsSortList, handleTree, importGoodsTemplate, selectDictLabel } from "../../api/goods/goods";
import { getToken } from "@/utils/auth";
import { Link } from "element-ui";

export default {
  name: "GoodsList",
  components: {
    "el-link": Link,
  },
  props: {},
  data() {
    return {
      url: "https://fuss10.elemecdn.com/a/3f/3302e58f9a181d2509f3dc0fa68b0jpeg.jpeg",
      currentDate: new Date(),
      taps: "1",
      queryParams: {
        pageNum: 1,
        pageSize: 10,
        searchValue: "",
        sortIds: "",
      },
      total: 0,
      filterText: "",
      sortsData: [],
      sortsList: [],
      defaultProps: {
        children: "children",
        label: "ssort",
      },
      goodsData: [],
      selectIds: [],
      upload: {
        open: false, //弹出导入
        title: "货品导入",
        isUploading: false,
        updateSupport: 0,
        headers: { Authorization: getToken() },
        url: "/api/goods/rep-goods/import",
      },
    };
  },
  watch: {
    filterText(val) {
      this.$refs.tree.filter(val);
    },
  },
  mounted() {
    this.fetchGoodsData();
    this.fetchGoodSorts();
  },
  methods: {
    JumpAddInfo() {
      this.$router.push({ path: "/goods/addInfo" });
    },
    filterNode(value, data) {
      if (!value) return true;
      return data.ssort.indexOf(value) !== -1;
    },
    handleCheckChange(data, checked) {
      if (checked) {
        this.selectIds.push(data.sid);
      } else {
        const index = this.selectIds.indexOf(data.sid);
        if (index > -1) {
          this.selectIds.splice(index, 1);
        }
      }
      this.queryParams.sortIds = this.selectIds.join(",");
      this.fetchGoodsData();
    },
    handlePageChange(currentPage) {
      this.queryParams.pageNum = currentPage;
      this.fetchGoodsData();
    },
    handleSizeChange(val) {
      this.queryParams.pageSize = val;
      this.fetchGoodsData();
    },
    fetchGoodsData() {
      goodsList(this.queryParams).then((response) => {
        this.goodsData = response.data;
        this.total = response.total;
      });
    },
    handleQueryGoods() {
      this.fetchGoodsData();
    },
    handleEditGoods(sid) {
      this.$router.push({ path: "/goods/addInfo", query: { sid: sid } });
    },
    handleViewGoods(sid, rsort, sortsData) {
      this.$router.push({ path: "/goods/goodsInfo", query: { sid: sid, rsort: rsort, sortsData: sortsData } });
    },
    handleDeleteGoods(row) {
      this.$confirm('是否确认删除货品:"' + row.cname + '"？')
        .then(function () {
          return deleteGoodsById(row.sid);
        })
        .then(() => {
          this.fetchGoodsData(this.queryParams);
          this.$message("删除成功");
        })
        .catch(() => {});
    },
    fetchGoodSorts() {
      goodsSortList().then((response) => {
        this.sortsList = response.data;
        this.sortsData = handleTree(response.data, "sid", "rsort");
      });
    },
    handleGoodsTemplate() {
      importGoodsTemplate();
    },
    handleFileUploadProgress(event, file, fileList) {
      this.upload.isUploading = true;
    },
    //文件上传成功后处理
    handleFileSuccess(response, file, fileList) {
      this.upload.open = false;
      this.upload.isUploading = false;
      this.$refs.upload.clearFiles();
      this.$alert("<div style='overflow: auto;overflow-x: hidden;max-height: 70vh;padding: 10px 20px 0;'>" + response.message + "</div>", "导入结果", { dangerouslyUseHTMLString: true });
      this.fetchGoodsData();
    },
    // 提交上传文件
    submitFileForm() {
      this.$refs.upload.submit();
    },
    handleExport() {
      exportGoods(this.queryParams);
    },
    handleCascaderChange(value) {
      this.goods.rsort = value[value.length - 1];
    },
  },
};
</script>

<style lang="scss" scoped>
.hideContent {
  width: 200px;
  display: block;
  overflow: hidden;
  word-break: keep-all;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.goodsList {
  display: flex;
  flex-direction: row;
  overflow: hidden;
  height: 100%;
  &__left {
    margin: 20px;
    width: 20%;
    min-width: 20%;
    overflow: auto;
  }
  &__right {
    flex: 1;
  }
}
</style>

<style lang="scss">
.custom-empty {
  background-color: #f7f7f7;
  width: 61px;
  height: 38px;
}
.image-slot {
  padding-left: 25px;
  padding-top: 11px;
}
.custom-cas-goods-list .el-input.is-disabled .el-input__inner {
  background-color: #ffffff;
  color: #001528;
  border: 0px;
  padding: 0px;
  height: 25px;
}
</style>
