<template>
  <!-- 货品字典 -->
  <div class="goodsDictionary">
    <div class="goodsDictionary__left scrollbar">
      <div class="marginBottom10">
        <el-input v-model="filterText" class="wHP" placeholder="输入关键字进行过滤" clearable>
          <el-button slot="append" icon="el-icon-search" @click="filteredTypeList"></el-button>
        </el-input>
      </div>

      <el-table ref="multipleTable" border :data="typeList" tooltip-effect="dark" style="width: 100%" highlight-current-row @current-change="handleType">
        <el-table-column label="字典名称" align="center" prop="dictName" />
        <el-table-column label="操作" align="center" fixed="right">
          <template slot-scope="scope">
            <el-button type="text" size="mini" @click="editDict(scope.row)">编辑</el-button>
            <el-button type="text" style="color: red" size="mini" @click="deleteDict(scope.row)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-button class="wHP marginTop10" @click="addDict">新增字典</el-button>
      <!--          <div v-for="item in typeList" :key="item.dictType" class="fs20 marginTop10" @click="handleType(item)">-->
      <!--            {{ item.dictName }}-->
      <!--            <br />-->
      <!--          </div>-->
      <!--          <el-button size="mini" class="w50" @click="addDict">新增</el-button>-->
      <!--          <el-button size="mini" class="w50" @click="editDict(item)">编辑</el-button>-->
      <!--          <el-button size="mini" type="danger" class="w50" @click="deleteDict(item)">删除</el-button>-->
    </div>
    <div class="goodsDictionary__right">
      <div class="bg-purple-light bcWhite margin10 padding10 h800">
        <el-row class="marginBottom10">
          <el-col class="borderBottom paddingBottom10 marginBottom10">
            <span class="fs20 fWB">字典列表</span>
          </el-col>
          <el-row class="textRight">
            <el-button @click="addDictItem">新增子项</el-button>
          </el-row>
        </el-row>
        <el-table ref="multipleTable" border :data="dataList" tooltip-effect="dark" style="width: 100%">
          <el-table-column label="序号" align="center" width="50" type="index" />
          <el-table-column label="键值" align="center" prop="dictValue" width="80" />
          <el-table-column label="名称" align="center" prop="dictLabel" />
          <el-table-column label="排序" align="center" prop="dictSort" width="80" />
          <el-table-column label="状态" align="center" prop="status" width="80" />
          <el-table-column label="操作人" align="center" prop="createBy" width="200" />
          <el-table-column label="更新时间" align="center" prop="createTime" />
          <el-table-column label="操作" align="center" fixed="right" width="160">
            <template slot-scope="scope">
              <el-button type="text" size="small" @click="editData(scope.row)">编辑</el-button>
              <el-button type="text" style="color: red" size="small" @click="deleteData(scope.row)">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
    <!-- 添加或修改字典数据项 -->
    <el-dialog class="elDialog" :close-on-click-modal="false" title="新增子项" :visible.sync="dialogVisible" width="30%" :before-close="handleClose" append-to-body>
      <div class="marginTop10">
        <el-form ref="form" :model="dataForm" :rules="dataRules">
          <el-form-item label="子项键值" prop="dictValue">
            <el-input v-model="dataForm.dictValue" class="w250"></el-input>
          </el-form-item>
          <el-form-item label="子项名称" prop="dictLabel">
            <el-input v-model="dataForm.dictLabel" class="w250"></el-input>
          </el-form-item>
          <el-form-item label="子项排序" prop="dictSort">
            <el-input v-model="dataForm.dictSort" class="w250"></el-input>
          </el-form-item>
          <el-form-item class="textRight">
            <el-button type="primary" @click="onSubmit">提交</el-button>
            <el-button @click="onCancel">取消</el-button>
          </el-form-item>
        </el-form>
      </div>
    </el-dialog>

    <!-- 添加或修改参数配置对话框 -->
    <el-dialog :visible.sync="dictDialogVisible" width="500px" append-to-body>
      <el-form ref="dictForm" :model="dictForm" label-width="90px" :rules="dictRules">
        <el-form-item label="字典名称" prop="dictName">
          <el-input v-model="dictForm.dictName" placeholder="请输入字典名称" />
        </el-form-item>
        <el-form-item label="字典类型" prop="dictType">
          <el-input v-model="dictForm.dictType" placeholder="请输入字典类型" />
        </el-form-item>
        <el-form-item label="备注" prop="remark">
          <el-input v-model="dictForm.remark" type="textarea" placeholder="请输入内容"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submitDict">提 交</el-button>
        <el-button @click="cancelDict">取 消</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { addType, delType, optionselect, updateType } from "@/api/goods/type";
import { addData, delData, listData, updateData } from "@/api/goods/data";

export default {
  name: "GoodsDictionary",
  props: {},
  data() {
    return {
      filterText: "",
      dataForm: {},
      dictForm: {},
      // 查询参数
      queryParams: {
        pageNum: 1,
        pageSize: 10,
        dictType: undefined,
        dictLabel: undefined,
        status: undefined,
      },
      selectType: "",
      tableData: "",
      dialogVisible: false,
      dictDialogVisible: false,
      isDisabled: false,
      typeList: [],
      allTypeList: [], //临时存一份全的，过滤时使用
      dataList: [],
      dictRules: {
        dictName: [
          { required: true, message: "字典名称为必填项", trigger: "blur" },
          { max: 20, message: "长度需小于20个字符", trigger: "blur" },
        ],
        dictType: [{ required: true, message: "字典类型为必填项", trigger: "blur" }],
      },
      dataRules: {
        dictValue: [{ required: true, message: "子项键值为必选项", trigger: "blur" }],
        dictLabel: [
          { required: true, message: "子项名称为必填项", trigger: "blur" },
          { max: 100, message: "长度需小于100个字符", trigger: "blur" },
        ],
        dictSort: [{ required: true, message: "子项排序为必填项", trigger: "blur" }],
      },
    };
  },
  watch: {
    filterText(val) {
      //this.$refs.tree.filter(val);
    },
  },
  created() {
    this.getList();
    this.getTypeList();
  },
  mounted() {},
  methods: {
    filteredTypeList() {
      this.typeList = this.allTypeList;
      if (this.filterText) {
        this.typeList = this.typeList.filter((item) => item.dictName.toLowerCase().includes(this.filterText.toLowerCase()));
      }
    },
    onSubmit() {
      this.$refs["form"].validate((valid) => {
        this.dataForm.dictType = this.selectType;
        if (valid) {
          if (this.dataForm.dictCode != undefined) {
            updateData(this.dataForm).then(() => {
              this.$message("修改成功");
              this.dialogVisible = false;
              this.isDisabled = false;
              this.getList();
            });
          } else {
            addData(this.dataForm).then(() => {
              this.$message("新增成功");
              this.dialogVisible = false;
              this.isDisabled = false;
              this.getList();
            });
          }
        }
      });
    },
    onCancel() {
      this.dataForm = {};
      this.dialogVisible = false;
    },
    editData(row) {
      this.dataForm = row;
      this.dialogVisible = true;
    },
    deleteData(row) {
      this.$confirm('是否确认删除字典子项为"' + row.dictLabel + '"？')
        .then(() => {
          return delData(row.dictCode);
        })
        .then(() => {
          this.getList();
          this.$message("删除成功");
        })
        .catch(() => {});
    },
    handleClose(done) {
      this.$confirm("确认关闭？")
        .then((_) => {
          done();
        })
        .catch((_) => {});
    },
    //新增字典数据项 点击事件，弹框
    addDictItem() {
      this.dataForm = {};
      if (this.selectType === "") {
        this.$message.error("请先选择字典");
        return;
      }
      this.dialogVisible = true;
    },
    handleType(item) {
      this.queryParams.dictType = item.dictType;
      if (this.selectType === item.dictType) return;
      this.selectType = item.dictType;
      this.getList();
    },
    /** 查询字典列表 */
    getTypeList() {
      optionselect().then((response) => {
        this.typeList = response.data;
        this.allTypeList = this.typeList;
      });
    },
    /** 查询字典数据项列表 */
    getList() {
      listData(this.queryParams).then((response) => {
        this.dataList = response.data;
        for (let i = 0; i < this.dataList.length; i++) {
          this.dataList[i].status = this.dataList[i].status === "0" ? "启用" : "停用";
        }
      });
    },
    addDict() {
      this.dictForm = {};
      this.dictDialogVisible = true;
    },
    editDict(row) {
      this.dictForm = row;
      this.dictDialogVisible = true;
    },
    deleteDict(row) {
      this.$confirm('是否确认删除名称为"' + row.dictName + '"的字典？')
        .then(() => {
          return delType(row.dictId);
        })
        .then(() => {
          this.getTypeList();
          this.$message("删除成功");
        })
        .catch(() => {});
    },
    submitDict() {
      this.$refs["dictForm"].validate((valid) => {
        if (valid) {
          if (this.dictForm.dictId != undefined) {
            updateType(this.dictForm).then(() => {
              this.$message("修改成功");
              this.dictDialogVisible = false;
              this.getTypeList();
            });
          } else {
            addType(this.dictForm).then(() => {
              this.$message("新增成功");
              this.dictDialogVisible = false;
              this.getTypeList();
            });
          }
        }
      });
    },
    cancelDict() {
      this.dictForm = {};
      this.dictDialogVisible = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.goodsDictionary {
  display: flex;
  flex-direction: row;
  overflow: hidden;
  height: 100%;
  &__left {
    margin: 20px;
    width: 20%;
    min-width: 20%;
    overflow: auto;
  }
  &__right {
    flex: 1;
  }
}
</style>
