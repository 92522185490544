<template>
  <!-- 货品标签 -->
  <div class="goodsLabel">
    <div class="goodsLabel__left scrollbar">
      <el-input v-model="filterText" class="wHP" placeholder="输入关键字进行过滤" clearable></el-input>
      <el-tree ref="tree" show-checkbox class="filter-tree" :data="sortsTreeData" :props="defaultProps" default-expand-all :filter-node-method="filterNode" @check-change="handleCheckChange" />
    </div>
    <div class="goodsLabel__right">
      <div class="bg-purple-light bcWhite margin10 padding10 h800">
        <el-row class="marginBottom10">
          <el-col class="borderBottom paddingBottom10 marginBottom10">
            <span class="fs20 fWB">标签列表</span>
          </el-col>
          <el-row>
            <el-col :span="12">
              <el-input v-model="queryParams.cName" placeholder="标签名称" class="input-with-select w300" clearable>
                <el-button slot="append" icon="el-icon-search" @click="handleQueryLabel"></el-button>
              </el-input>
            </el-col>
            <el-col :span="12" class="textRight">
              <el-button type="primary" @click="handleAdd">+添加标签</el-button>
              <el-button @click="handleDelete">删除</el-button>
            </el-col>
          </el-row>
        </el-row>
        <el-table ref="multipleTable" border :data="labelData" tooltip-effect="dark" class="h650 scrollAuto scrollbar" @selection-change="handleSelectionChange">
          <el-table-column type="selection" align="center" />
          <el-table-column label="序号" align="center" type="index" />
          <el-table-column label="所属分类" align="center" prop="rsort" />
          <el-table-column label="标签名称" align="center" prop="cname" />
          <el-table-column label="排序号" width="100" align="center" prop="nindex" />
          <el-table-column label="操作人" width="120" align="center" prop="supdate" />
          <el-table-column label="更新时间" align="center" prop="tupdate" />
          <el-table-column label="操作" align="center" fixed="right">
            <template slot-scope="scope">
              <el-button class="visibility-visible" type="text" size="small" @click="handleView(scope.row)">查看</el-button>
              <el-button class="visibility-visible" type="text" size="small" @click="handleEdit(scope.row)">编辑</el-button>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination :current-page="queryParams.pageNum" :page-sizes="[10, 20, 30, 50]" :page-size="queryParams.pageSize" layout="total, sizes, prev, pager, next, jumper" :total="total" @size-change="handleSizeChange" @current-change="handlePageChange"> </el-pagination>
      </div>
    </div>

    <!-- 添加或修改标签 -->
    <el-dialog :title="title" :visible.sync="open" width="600px" append-to-body>
      <el-form ref="form" :model="form" label-width="90px" :disabled="isDisabled" :rules="rules">
        <el-row>
          <el-col :span="12">
            <el-form-item required label="所属分类" prop="rsort">
              <el-cascader :value="form.rsort" :options="sortsTreeData" :props="{ value: 'sid', label: 'ssort' }" placeholder="请选择" class="custom-cas" @change="handleCascaderChange"></el-cascader>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item required label="标签名称" prop="cname">
              <el-input v-model="form.cname" placeholder="请输入标签名称" style="width: 400px" />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="排序号" prop="nindex">
              <el-input-number v-model="form.nindex" controls-position="right" :min="0" />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button v-if="title != '查看标签'" type="primary" @click="submitForm">确 定</el-button>
        <el-button @click="cancel">取 消</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { goodsSortList, handleTree, selectDictLabel } from "../../api/goods/goods";
import { addLabel, deleteLabels, getLabelById, labelList, updateLabel } from "../../api/goods/goodsLabel";

export default {
  name: "GoodsLabel",
  props: {},
  data() {
    return {
      tabs: "1",
      title: "", //弹框标题
      ids: [], //删除操作时选中id
      cnames: [], //删除操作时选中标签名称
      isDisabled: false, //弹框数据是否可编辑
      form: {}, //表单
      open: false, //弹框数据是否显示
      //列表查询条件
      queryParams: {
        pageNum: 1,
        pageSize: 10,
        cName: "",
        sortIds: "",
      },
      total: 0,
      labelData: [], //标签数据
      filterText: "",
      sortsTreeData: [], //分类树数据
      sortsListData: [], //分类列表数据
      defaultProps: {
        children: "children",
        label: "ssort",
      },
      selectIds: [], //选中分类数据
      rules: {
        rsort: [{ required: true, message: "所属分类为必选项", trigger: "blur" }],
        cname: [
          { required: true, message: "标签名称为必填项", trigger: "blur" },
          { max: 50, message: "长度需小于50个字符", trigger: "blur" },
        ],
      },
    };
  },
  watch: {
    filterText(val) {
      this.$refs.tree.filter(val);
    },
  },
  created() {
    this.fetchGoodSorts();
  },
  methods: {
    filterNode(value, data) {
      if (!value) return true;
      return data.ssort.indexOf(value) !== -1;
    },
    // 列表查询
    fetchData() {
      labelList(this.queryParams).then((response) => {
        this.labelData = response.data;
        this.total = response.total;
        for (let label of this.labelData) {
          label.rsort = selectDictLabel(this.sortsListData, label.rsort);
        }
      });
    },
    //查询分类数据
    fetchGoodSorts() {
      goodsSortList().then((response) => {
        this.sortsListData = response.data;
        this.sortsTreeData = handleTree(response.data, "sid", "rsort");
        this.fetchData();
      });
    },
    handleQueryLabel() {
      this.fetchData(this.queryParams);
    },
    handleAdd() {
      this.reset();
      this.open = true;
      this.title = "添加标签";
      this.isDisabled = false;
      this.fetchData();
    },
    handleEdit(row) {
      this.reset();
      this.isDisabled = false;
      getLabelById(row.sid).then((response) => {
        this.form = response.data;
        this.open = true;
        this.title = "编辑标签";
      });
    },
    handleView(row) {
      this.reset();
      getLabelById(row.sid).then((response) => {
        this.form = response.data;
        this.open = true;
        this.isDisabled = true;
        this.title = "查看标签";
      });
    },
    submitForm() {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          if (this.form.nindex == undefined) {
            this.form.nindex = 0;
          }
          if (this.form.sid != undefined) {
            updateLabel(this.form).then(() => {
              this.$message.success("修改成功");
              this.open = false;
              this.isDisabled = false;
              this.fetchData();
            });
          } else {
            addLabel(this.form).then(() => {
              this.$message.success("新增成功");
              this.open = false;
              this.isDisabled = false;
              this.fetchData();
            });
          }
        }
      });
    }, // 取消按钮
    cancel() {
      this.open = false;
      this.isDisabled = false;
      this.reset();
    },
    // 表单重置
    reset() {
      this.form = {
        rsort: "",
        cname: undefined,
        nindex: undefined,
        sid: undefined,
      };
      if (this.$refs["form"]) {
        this.$refs["form"].resetFields();
      }
    },
    // 选中数据
    handleSelectionChange(selection) {
      this.ids = selection.map((item) => item.sid);
      this.cnames = selection.map((item) => item.cname);
    },
    handleDelete() {
      //删除
      if (this.ids.length == 0) {
        this.$message.error("请选择需要删除的标签");
        return;
      }
      this.$confirm('是否确认删除标签名称为"' + this.cnames + '"？')
        .then(() => {
          return deleteLabels(this.ids);
        })
        .then(() => {
          this.fetchData();
          this.$message("删除成功");
        })
        .catch(() => {});
    },
    //分类选定的id
    handleCheckChange(data, checked) {
      if (checked) {
        this.selectIds.push(data.sid);
      } else {
        const index = this.selectIds.indexOf(data.sid);
        if (index > -1) {
          this.selectIds.splice(index, 1);
        }
      }
      this.queryParams.sortIds = this.selectIds.join(",");
    },
    handleCascaderChange(value) {
      this.form.rsort = value[value.length - 1];
    },
    handlePageChange(currentPage) {
      this.queryParams.pageNum = currentPage;
      this.fetchData();
    },
    handleSizeChange(val) {
      this.queryParams.pageSize = val;
      this.fetchData();
    },
  },
};
</script>

<style lang="scss" scoped>
.goodsLabel {
  display: flex;
  flex-direction: row;
  overflow: hidden;
  height: 100%;
  &__left {
    margin: 20px;
    width: 20%;
    min-width: 20%;
    overflow: auto;
  }
  &__right {
    flex: 1;
  }
}
</style>

<style lang="scss">
.custom-cas .el-input {
  width: 400px;
}
</style>
