import request from "../../utils/request";

/**分类列表
 *
 * @param params
 * @returns {*}
 */
export function sortsList(params) {
  return request({
    url: "/api/repository/repGoodsSorts/list",
    method: "get",
    params: params,
  });
}

/**根据id查询分类
 *
 * @param sid
 * @returns {*}
 */
export function getSortsById(sid) {
  return request({
    url: "/api/repository/repGoodsSorts/get/" + sid,
    method: "get",
  });
}

/**添加分类
 *
 * @param data
 * @returns {*}
 */
export function addSorts(data) {
  return request({
    url: "/api/repository/repGoodsSorts/add",
    method: "post",
    data: data,
  });
}

/**更新分类
 *
 * @param data
 * @returns {*}
 */
export function updateSorts(data) {
  return request({
    url: "/api/repository/repGoodsSorts/update",
    method: "put",
    data: data,
  });
}

/**删除分类
 *
 * @param sid
 * @returns {*}
 */
export function deleteSorts(sid) {
  return request({
    url: "/api/repository/repGoodsSorts/delete/" + sid,
    method: "delete",
  });
}
