<template>
  <!-- 货品分类 -->
  <div class="goodsSort scrollbar" style="padding-left: 10px; padding-top: 10px">
    <el-form v-show="showSearch" ref="queryForm" :model="queryParams" size="small" :inline="true">
      <el-form-item>
        <el-input v-model="queryParams.ssort" placeholder="分类名称" class="input-with-select w300" clearable style="margin-right: 300px">
          <el-button slot="append" icon="el-icon-search" @click="handleQuery"></el-button>
        </el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" plain icon="el-icon-plus" size="mini" @click="handleAdd()">添加一级分类</el-button>
      </el-form-item>
    </el-form>

    <el-table v-if="refreshTable" v-loading="loading" :data="sortList" row-key="sid" :default-expand-all="isExpandAll" :tree-props="{ children: 'children', hasChildren: 'hasChildren' }">
      <el-table-column prop="ssort" label="分类名称" width="260"></el-table-column>
      <el-table-column prop="nindex" label="排序" width="200"></el-table-column>
      <!--      <el-table-column prop="bvalid" label="状态" width="100"> </el-table-column>-->
      <el-table-column label="操作" align="left" class-name="small-padding fixed-width">
        <template slot-scope="scope">
          <el-button size="mini" type="text" icon="el-icon-edit" @click="handleUpdate(scope.row)">修改</el-button>
          <el-button size="mini" type="text" icon="el-icon-plus" @click="handleAdd(scope.row)">新增</el-button>
          <el-button v-if="scope.row.rsort != undefined" size="mini" type="text" style="color: red" icon="el-icon-delete" @click="handleDelete(scope.row)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>

    <!-- 添加或修改分类对话框 -->
    <el-dialog :title="title" :visible.sync="open" width="600px" append-to-body>
      <el-form ref="form" :model="form" :rules="rules" label-width="90px">
        <el-row>
          <el-col v-if="form.rsort !== 0" :span="24">
            <el-form-item label="上级分类" prop="rsort">
              <el-cascader :value="form.rsort" :options="sortOptions" :props="{ value: 'sid', label: 'ssort', checkStrictly: true }" placeholder="选择上级分类" class="custom-cas" @change="handleCascaderChange"></el-cascader>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="分类名称" prop="ssort">
              <el-input v-model="form.ssort" placeholder="请输入分类名称" style="width: 400px" />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="显示排序" prop="nindex">
              <el-input-number v-model="form.nindex" controls-position="right" :min="0" />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submitForm">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { addSorts, deleteSorts, getSortsById, sortsList, updateSorts } from "@/api/goods/goodsSort";
import { handleTree } from "@/api/goods/goods";

export default {
  name: "GoodsSort",
  data() {
    return {
      // 遮罩层
      loading: true,
      // 显示搜索条件
      showSearch: true,
      // 表格树数据
      sortList: [],
      // 分类树选项
      sortOptions: [],
      // 弹出层标题
      title: "",
      // 是否显示弹出层
      open: false,
      // 是否展开，默认全部展开
      isExpandAll: true,
      // 重新渲染表格状态
      refreshTable: true,
      // 查询参数
      queryParams: {
        ssort: undefined,
      },
      // 表单参数
      form: {},
      // 表单校验
      rules: {
        ssort: [{ required: true, message: "分类名称不能为空", trigger: "blur" }],
      },
    };
  },
  created() {
    this.getList();
  },
  methods: {
    /** 查询分类列表 */
    getList() {
      this.loading = true;
      sortsList(this.queryParams).then((response) => {
        this.sortList = handleTree(response.data, "sid", "rsort");
        this.loading = false;
      });
    },
    // 表单重置
    reset() {
      this.form = {
        sid: undefined,
        rsort: undefined,
        ssort: undefined,
        nindex: 0,
        bvalid: "0",
      };
    },
    /** 搜索按钮操作 */
    handleQuery() {
      this.getList();
    },
    /** 新增按钮操作 */
    handleAdd(row) {
      this.reset();
      if (row != undefined) {
        this.form.rsort = row.sid;
      }
      this.open = true;
      this.title = "添加分类";
      sortsList().then((response) => {
        this.sortOptions = handleTree(response.data, "sid", "rsort");
      });
    },
    /** 修改按钮操作 */
    handleUpdate(row) {
      this.reset();
      getSortsById(row.sid).then((response) => {
        this.form = response.data;
        this.open = true;
        this.title = "修改分类";
      });
      sortsList().then((response) => {
        this.sortOptions = handleTree(response.data, "sid", "rsort");
      });
    },
    /** 提交按钮 */
    submitForm: function () {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          if (this.form.sid != undefined) {
            updateSorts(this.form).then((response) => {
              this.$message.success("修改成功");
              this.open = false;
              this.getList();
            });
          } else {
            addSorts(this.form).then((response) => {
              this.$message.success("新增成功");
              this.open = false;
              this.getList();
            });
          }
        }
      });
    },
    /** 删除按钮操作 */
    handleDelete(row) {
      this.$confirm('是否确认删除名称为"' + row.ssort + '"的数据项？')
        .then(function () {
          return deleteSorts(row.sid);
        })
        .then(() => {
          this.getList();
          this.$message.success("删除成功");
        })
        .catch(() => {});
    },
    handleCascaderChange(value) {
      if (value) {
        this.form.rsort = value[value.length - 1];
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.goodsSort {
  overflow: auto;
  height: 100%;
}
</style>
