var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "goodsBrand" },
    [
      _c(
        "div",
        { staticClass: "goodsBrand__left paddingRight20 scrollbar" },
        [
          _c("el-input", {
            staticClass: "wHP",
            attrs: { placeholder: "输入关键字进行过滤", clearable: "" },
            model: {
              value: _vm.filterText,
              callback: function ($$v) {
                _vm.filterText = $$v
              },
              expression: "filterText",
            },
          }),
          _c("el-tree", {
            ref: "tree",
            staticClass: "filter-tree",
            attrs: {
              "show-checkbox": "",
              "node-key": "sid",
              data: _vm.sortsTreeData,
              props: _vm.defaultProps,
              "default-expand-all": "",
              "filter-node-method": _vm.filterNode,
            },
            on: { "check-change": _vm.handleCheckChange },
          }),
        ],
        1
      ),
      _c("div", { staticClass: "goodsBrand__right" }, [
        _c(
          "div",
          { staticClass: "bg-purple-light bcWhite margin10 padding10 h800" },
          [
            _c(
              "el-row",
              { staticClass: "marginBottom10" },
              [
                _c(
                  "el-col",
                  {
                    staticClass: "borderBottom paddingBottom10 marginBottom10",
                  },
                  [
                    _c("span", { staticClass: "fs20 fWB" }, [
                      _vm._v("品牌列表"),
                    ]),
                  ]
                ),
                _c(
                  "el-row",
                  [
                    _c(
                      "el-col",
                      { attrs: { span: 12 } },
                      [
                        _c(
                          "el-input",
                          {
                            staticClass: "input-with-select w300",
                            attrs: { placeholder: "品牌名称", clearable: "" },
                            on: { change: _vm.handleQueryLabel },
                            model: {
                              value: _vm.queryParams.cName,
                              callback: function ($$v) {
                                _vm.$set(_vm.queryParams, "cName", $$v)
                              },
                              expression: "queryParams.cName",
                            },
                          },
                          [
                            _c("el-button", {
                              attrs: { slot: "append", icon: "el-icon-search" },
                              on: { click: _vm.handleQueryLabel },
                              slot: "append",
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { staticClass: "textRight", attrs: { span: 12 } },
                      [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "primary" },
                            on: { click: _vm.handleAdd },
                          },
                          [_vm._v("+添加品牌")]
                        ),
                        _c("el-button", { on: { click: _vm.resetSearch } }, [
                          _vm._v("重置搜索"),
                        ]),
                        _c("el-button", { on: { click: _vm.handleDelete } }, [
                          _vm._v("删除"),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "el-table",
              {
                ref: "multipleTable",
                staticClass: "h650 scrollAuto scrollbar",
                attrs: {
                  border: "",
                  data: _vm.brandData,
                  "tooltip-effect": "dark",
                },
                on: { "selection-change": _vm.handleSelectionChange },
              },
              [
                _c("el-table-column", {
                  attrs: { type: "selection", align: "center" },
                }),
                _c("el-table-column", {
                  attrs: { label: "序号", align: "center", type: "index" },
                }),
                _c("el-table-column", {
                  attrs: { label: "所属分类", align: "center", prop: "rsort" },
                }),
                _c("el-table-column", {
                  attrs: { label: "所属编号", align: "center", prop: "ccode" },
                }),
                _c("el-table-column", {
                  attrs: { label: "LOGO", align: "center", prop: "clogo" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c("el-image", {
                            staticStyle: { width: "40px", height: "30px" },
                            attrs: { src: scope.row.clogo },
                            on: {
                              click: function ($event) {
                                return _vm.preview(scope.row.clogo)
                              },
                            },
                          }),
                        ]
                      },
                    },
                  ]),
                }),
                _c("el-table-column", {
                  attrs: { label: "品牌名称", align: "center", prop: "cname" },
                }),
                _c("el-table-column", {
                  attrs: {
                    label: "排序号",
                    align: "center",
                    prop: "nindex",
                    width: "60",
                  },
                }),
                _c("el-table-column", {
                  attrs: {
                    label: "状态",
                    align: "center",
                    prop: "bvalid",
                    width: "60",
                  },
                }),
                _c("el-table-column", {
                  attrs: {
                    label: "更新时间",
                    align: "center",
                    prop: "tupdate",
                    width: "160",
                  },
                }),
                _c("el-table-column", {
                  attrs: { label: "操作", align: "center", fixed: "right" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c(
                            "el-button",
                            {
                              staticClass: "visibility-visible",
                              attrs: { type: "text", size: "small" },
                              on: {
                                click: function ($event) {
                                  return _vm.handleView(scope.row)
                                },
                              },
                            },
                            [_vm._v("查看")]
                          ),
                          _c(
                            "el-button",
                            {
                              staticClass: "visibility-visible",
                              attrs: { type: "text", size: "small" },
                              on: {
                                click: function ($event) {
                                  return _vm.handleEdit(scope.row)
                                },
                              },
                            },
                            [_vm._v("编辑")]
                          ),
                        ]
                      },
                    },
                  ]),
                }),
              ],
              1
            ),
            _c("el-pagination", {
              attrs: {
                "current-page": _vm.queryParams.pageNum,
                "page-sizes": [10, 20, 30, 50],
                "page-size": _vm.queryParams.pageSize,
                layout: "total, sizes, prev, pager, next, jumper",
                total: _vm.total,
              },
              on: {
                "size-change": _vm.handleSizeChange,
                "current-change": _vm.handlePageChange,
              },
            }),
          ],
          1
        ),
      ]),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.title,
            visible: _vm.open,
            width: "750px",
            "append-to-body": "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.open = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "form",
              attrs: {
                model: _vm.form,
                "label-width": "90px",
                disabled: _vm.isDisabled,
                rules: _vm.rules,
              },
            },
            [
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "所属分类", prop: "rsort" } },
                        [
                          _c("el-cascader", {
                            attrs: {
                              value: _vm.form.rsort,
                              clearable: "",
                              options: _vm.sortsTreeData,
                              props: { value: "sid", label: "ssort" },
                              placeholder: "请选择",
                            },
                            on: { change: _vm.handleCascaderChange },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "编号", prop: "ccode" } },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "请输入编号" },
                            model: {
                              value: _vm.form.ccode,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "ccode", $$v)
                              },
                              expression: "form.ccode",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "LOGO", prop: "clogo" } },
                        [
                          _vm.title === "查看品牌"
                            ? _c("el-image", {
                                staticStyle: {
                                  width: "148px",
                                  height: "148px",
                                },
                                attrs: { src: _vm.imgUrl },
                              })
                            : _c(
                                "image-upload",
                                {
                                  staticClass:
                                    "w150 h150 marginBottom20 marginTop10",
                                  attrs: { limit: 1, "is-show-tip": false },
                                  on: { input: _vm.fileList },
                                },
                                [_vm._v("+上传图片")]
                              ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            required: "",
                            label: "品牌名称",
                            prop: "cname",
                          },
                        },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "请输入品牌名称" },
                            model: {
                              value: _vm.form.cname,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "cname", $$v)
                              },
                              expression: "form.cname",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "排序", prop: "nindex" } },
                        [
                          _c("el-input-number", {
                            attrs: { "controls-position": "right", min: 0 },
                            model: {
                              value: _vm.form.nindex,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "nindex", $$v)
                              },
                              expression: "form.nindex",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "状态", prop: "bvalid" } },
                        [
                          _c("el-switch", {
                            attrs: { "controls-position": "right", min: 0 },
                            model: {
                              value: _vm.form.bvalid,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "bvalid", $$v)
                              },
                              expression: "form.bvalid",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _vm.title !== "查看品牌"
                ? _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.submitForm },
                    },
                    [_vm._v("确 定")]
                  )
                : _vm._e(),
              _c("el-button", { on: { click: _vm.cancel } }, [_vm._v("取 消")]),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.dialogVisible,
            title: "预览",
            "append-to-body": "",
            width: "25%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c("img", {
            staticStyle: { display: "block", margin: "auto", width: "100%" },
            attrs: { src: _vm.dialogImageUrl },
          }),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }