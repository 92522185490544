<template>
  <!-- 货品品牌 -->
  <div class="goodsBrand">
    <div class="goodsBrand__left paddingRight20 scrollbar">
      <el-input v-model="filterText" class="wHP" placeholder="输入关键字进行过滤" clearable></el-input>
      <el-tree ref="tree" show-checkbox :node-key="'sid'" class="filter-tree" :data="sortsTreeData" :props="defaultProps" default-expand-all :filter-node-method="filterNode" @check-change="handleCheckChange" />
    </div>
    <div class="goodsBrand__right">
      <div class="bg-purple-light bcWhite margin10 padding10 h800">
        <el-row class="marginBottom10">
          <el-col class="borderBottom paddingBottom10 marginBottom10">
            <span class="fs20 fWB">品牌列表</span>
          </el-col>
          <el-row>
            <el-col :span="12">
              <el-input v-model="queryParams.cName" placeholder="品牌名称" class="input-with-select w300" clearable @change="handleQueryLabel">
                <el-button slot="append" icon="el-icon-search" @click="handleQueryLabel"></el-button>
              </el-input>
            </el-col>
            <el-col :span="12" class="textRight">
              <el-button type="primary" @click="handleAdd">+添加品牌</el-button>
              <el-button @click="resetSearch">重置搜索</el-button>
              <el-button @click="handleDelete">删除</el-button>
            </el-col>
          </el-row>
        </el-row>
        <el-table ref="multipleTable" border :data="brandData" tooltip-effect="dark" class="h650 scrollAuto scrollbar" @selection-change="handleSelectionChange">
          <el-table-column type="selection" align="center" />
          <el-table-column label="序号" align="center" type="index" />
          <el-table-column label="所属分类" align="center" prop="rsort" />
          <el-table-column label="所属编号" align="center" prop="ccode" />
          <el-table-column label="LOGO" align="center" prop="clogo">
            <template slot-scope="scope">
              <el-image :src="scope.row.clogo" style="width: 40px; height: 30px" @click="preview(scope.row.clogo)"></el-image>
            </template>
          </el-table-column>
          <el-table-column label="品牌名称" align="center" prop="cname" />
          <el-table-column label="排序号" align="center" prop="nindex" width="60" />
          <el-table-column label="状态" align="center" prop="bvalid" width="60" />
          <el-table-column label="更新时间" align="center" prop="tupdate" width="160" />
          <el-table-column label="操作" align="center" fixed="right">
            <template slot-scope="scope">
              <el-button class="visibility-visible" type="text" size="small" @click="handleView(scope.row)">查看</el-button>
              <el-button class="visibility-visible" type="text" size="small" @click="handleEdit(scope.row)">编辑</el-button>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination :current-page="queryParams.pageNum" :page-sizes="[10, 20, 30, 50]" :page-size="queryParams.pageSize" layout="total, sizes, prev, pager, next, jumper" :total="total" @size-change="handleSizeChange" @current-change="handlePageChange"></el-pagination>
      </div>
    </div>

    <!-- 添加或修改品牌 -->
    <el-dialog :title="title" :visible.sync="open" width="750px" append-to-body>
      <el-form ref="form" :model="form" label-width="90px" :disabled="isDisabled" :rules="rules">
        <el-row>
          <el-col :span="12">
            <el-form-item label="所属分类" prop="rsort">
              <el-cascader :value="form.rsort" clearable :options="sortsTreeData" :props="{ value: 'sid', label: 'ssort' }" placeholder="请选择" @change="handleCascaderChange"></el-cascader>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="编号" prop="ccode">
              <el-input v-model="form.ccode" placeholder="请输入编号" />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="LOGO" prop="clogo">
              <el-image v-if="title === '查看品牌'" :src="imgUrl" style="width: 148px; height: 148px"></el-image>
              <image-upload v-else class="w150 h150 marginBottom20 marginTop10" :limit="1" :is-show-tip="false" @input="fileList">+上传图片</image-upload>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item required label="品牌名称" prop="cname">
              <el-input v-model="form.cname" placeholder="请输入品牌名称" />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="排序" prop="nindex">
              <el-input-number v-model="form.nindex" controls-position="right" :min="0" />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="状态" prop="bvalid">
              <el-switch v-model="form.bvalid" controls-position="right" :min="0" />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button v-if="title !== '查看品牌'" type="primary" @click="submitForm">确 定</el-button>
        <el-button @click="cancel">取 消</el-button>
      </div>
    </el-dialog>

    <el-dialog :visible.sync="dialogVisible" title="预览" append-to-body width="25%">
      <img :src="dialogImageUrl" style="display: block; margin: auto; width: 100%" />
    </el-dialog>
  </div>
</template>

<script>
import { goodsSortList, handleTree, selectDictLabel } from "../../api/goods/goods";
import { addBrand, deleteBrands, getBrandById, brandList, updateBrand } from "../../api/goods/goodsBrand";
import ImageUpload from "@/components/ImageUpload";
import { isImage } from "@/api/commons";

export default {
  name: "GoodBrand",
  components: { ImageUpload },
  props: {},
  data() {
    return {
      tabs: "1",
      title: "", //新增修改弹框标题
      ids: [], //删除操作时选中id
      cnames: [], //删除操作时选中品牌名称
      sortsListData: [], //分类列表数据
      sortsTreeData: [], //分类树形数据
      isDisabled: false, //弹框数据是否可编辑
      form: {}, //表单数据
      open: false, //编辑查看弹框是否显示
      imgUrl: "",
      dialogVisible: false,
      dialogImageUrl: "",
      //列表查询条件
      queryParams: {
        pageNum: 1,
        pageSize: 10,
        cName: "",
        sortIds: "",
      },
      total: 0,
      brandData: [], //列表数据
      filterText: "",
      defaultProps: {
        children: "children",
        label: "ssort",
      },
      selectedNode: [], // 存储已选节点的数组
      expandedKeys: [],
      autoExpandParent: true,
      selectIds: [], //选中的分类id
      rules: {
        cname: [
          { required: true, message: "品牌名称为必填项", trigger: "blur" },
          { max: 50, message: "长度需小于50个字符", trigger: "blur" },
        ],
      },
    };
  },
  watch: {
    filterText(val) {
      this.$refs.tree.filter(val);
    },
  },
  created() {
    this.fetchGoodSorts();
  },
  methods: {
    resetSearch() {
      this.$refs.tree.setCheckedKeys([]);
      this.selectIds = [];
      this.queryParams.sortIds = "";
      this.queryParams.cName = "";
      this.handleQueryLabel();
    },
    filterNode(value, data) {
      if (!value) return true;
      return data.ssort.indexOf(value) !== -1;
    },
    fetchData() {
      //加载列表数据
      brandList(this.queryParams).then((response) => {
        this.brandData = response.data;
        this.total = response.total;
        for (let brand of this.brandData) {
          brand.rsort = selectDictLabel(this.sortsListData, brand.rsort);
          if (brand.bvalid) {
            brand.bvalid = "有效";
          } else {
            brand.bvalid = "无效";
          }
          brand.clogo = isImage(brand.clogo);
        }
      });
    },
    fetchGoodSorts() {
      //获取分类数据
      goodsSortList().then((response) => {
        this.sortsListData = response.data;
        this.sortsTreeData = handleTree(response.data, "sid", "rsort");
        this.fetchData();
      });
    },
    handleQueryLabel() {
      this.fetchData();
    },
    handleAdd() {
      this.reset();
      this.open = true;
      this.isDisabled = false;
      this.title = "添加品牌";
      this.fetchData();
    },
    handleEdit(row) {
      this.reset();
      this.isDisabled = false;
      getBrandById(row.sid).then((response) => {
        this.form = response.data;
        this.open = true;
        this.title = "编辑品牌";
      });
    },
    handleView(row) {
      this.reset();
      this.isDisabled = true;
      getBrandById(row.sid).then((response) => {
        this.form = response.data;
        this.open = true;
        this.imgUrl = isImage(this.form.clogo);
        this.title = "查看品牌";
      });
    },
    submitForm() {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          if (this.form.sid !== undefined) {
            updateBrand(this.form).then(() => {
              this.$message("修改成功");
              this.open = false;
              this.isDisabled = false;
              this.fetchData();
            });
          } else {
            addBrand(this.form).then(() => {
              this.$message("新增成功");
              this.open = false;
              this.isDisabled = false;
              this.fetchData();
            });
          }
        }
      });
    }, // 取消按钮
    cancel() {
      this.open = false;
      this.isDisabled = false;
      this.reset();
    },
    // 表单重置
    reset() {
      this.form = {
        rsort: "",
        ccode: undefined,
        clogo: undefined,
        cname: undefined,
        nindex: undefined,
        bvalid: undefined,
        sid: undefined,
      };
      if (this.$refs["form"]) {
        this.$refs["form"].resetFields();
      }
    },
    preview(image) {
      this.dialogVisible = true;
      this.dialogImageUrl = image;
    },
    // 选中数据
    handleSelectionChange(selection) {
      this.ids = selection.map((item) => item.sid);
      this.cnames = selection.map((item) => item.cname);
    },
    handleDelete() {
      //删除
      if (this.ids.length === 0) {
        this.$message.error("请选择需要删除的品牌");
        return;
      }
      this.$confirm('是否确认删除品牌名称为"' + this.cnames + '"？')
        .then(() => {
          return deleteBrands(this.ids);
        })
        .then(() => {
          this.fetchData();
          this.$message("删除成功");
        })
        .catch(() => {});
    },
    handleCascaderChange(value) {
      this.form.rsort = value[value.length - 1];
    },
    //获取所有选择的分类id
    handleCheckChange(data, checked) {
      if (checked) {
        this.selectIds.push(data.sid);
      } else {
        const index = this.selectIds.indexOf(data.sid);
        if (index > -1) {
          this.selectIds.splice(index, 1);
        }
      }
      this.queryParams.sortIds = this.selectIds.join(",");
    },
    handlePageChange(currentPage) {
      this.queryParams.pageNum = currentPage;
      this.fetchData();
    },
    handleSizeChange(val) {
      this.queryParams.pageSize = val;
      this.fetchData();
    },
    fileList(files) {
      this.form.clogo = files.split(",")[0];
    },
  },
};
</script>

<style lang="scss" scoped>
.goodsBrand {
  display: flex;
  flex-direction: row;
  overflow: hidden;
  height: 100%;

  &__left {
    margin: 20px;
    width: 20%;
    min-width: 20%;
    overflow: auto;
  }

  &__right {
    flex: 1;
  }
}
</style>
