var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "goodsList" },
    [
      _c(
        "div",
        { staticClass: "goodsList__left scrollbar" },
        [
          _c("el-input", {
            staticClass: "wHP",
            attrs: { placeholder: "输入关键字进行过滤", clearable: "" },
            model: {
              value: _vm.filterText,
              callback: function ($$v) {
                _vm.filterText = $$v
              },
              expression: "filterText",
            },
          }),
          _c("el-tree", {
            ref: "tree",
            staticClass: "filter-tree",
            attrs: {
              "show-checkbox": "",
              data: _vm.sortsData,
              props: _vm.defaultProps,
              "default-expand-all": "",
              "filter-node-method": _vm.filterNode,
            },
            on: { "check-change": _vm.handleCheckChange },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "goodsList__right" },
        [
          _c("div", { staticClass: "flexGrow1 scrollbar" }, [
            _c(
              "div",
              { staticClass: "bcWhite margin10 padding10" },
              [
                _c(
                  "el-row",
                  { staticClass: "marginBottom10" },
                  [
                    _c(
                      "el-col",
                      {
                        staticClass:
                          "borderBottom paddingBottom10 marginBottom10",
                      },
                      [
                        _c("span", { staticClass: "fs20 fWB" }, [
                          _vm._v("货品列表"),
                        ]),
                      ]
                    ),
                    _c(
                      "el-row",
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 12 } },
                          [
                            _c(
                              "el-input",
                              {
                                staticClass: "input-with-select w400",
                                attrs: {
                                  placeholder: "货品名称/货品编号/SKU",
                                  clearable: "",
                                },
                                on: { change: _vm.handleQueryGoods },
                                model: {
                                  value: _vm.queryParams.searchValue,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.queryParams,
                                      "searchValue",
                                      $$v
                                    )
                                  },
                                  expression: "queryParams.searchValue",
                                },
                              },
                              [
                                _c("el-button", {
                                  attrs: {
                                    slot: "append",
                                    icon: "el-icon-search",
                                  },
                                  on: { click: _vm.handleQueryGoods },
                                  slot: "append",
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          { staticClass: "textRight", attrs: { span: 12 } },
                          [
                            _c(
                              "el-button",
                              { on: { click: _vm.handleGoodsTemplate } },
                              [_vm._v("下载模板")]
                            ),
                            _c(
                              "el-button",
                              {
                                on: {
                                  click: function ($event) {
                                    _vm.upload.open = true
                                  },
                                },
                              },
                              [_vm._v("导入货品")]
                            ),
                            _c(
                              "el-button",
                              { on: { click: _vm.handleExport } },
                              [_vm._v("导出货品")]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-row",
                  {
                    staticClass: "scrollbar",
                    staticStyle: { height: "644px" },
                    attrs: { gutter: 10 },
                  },
                  [
                    _c("el-col", { attrs: { span: 8 } }, [
                      _c(
                        "div",
                        { staticClass: "h150" },
                        [
                          _c(
                            "el-button",
                            {
                              staticClass: "wHP hHP",
                              on: { click: _vm.JumpAddInfo },
                            },
                            [_vm._v("+添加货品")]
                          ),
                        ],
                        1
                      ),
                    ]),
                    _vm._l(_vm.goodsData, function (goods, index) {
                      return _c("el-col", { key: index, attrs: { span: 8 } }, [
                        _c(
                          "div",
                          { staticClass: "marginBottom10" },
                          [
                            _c(
                              "el-card",
                              { attrs: { "body-style": { padding: "0" } } },
                              [
                                _c(
                                  "el-row",
                                  {
                                    staticClass: "margin10",
                                    attrs: { gutter: 10 },
                                  },
                                  [
                                    _c("el-col", { attrs: { span: 5 } }, [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "bg-purple",
                                          staticStyle: { "margin-top": "5px" },
                                        },
                                        [
                                          goods.cmasterUrl === undefined
                                            ? _c(
                                                "el-image",
                                                { staticClass: "custom-empty" },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass: "image-slot",
                                                      attrs: { slot: "error" },
                                                      slot: "error",
                                                    },
                                                    [
                                                      _c("i", {
                                                        staticClass:
                                                          "el-icon-picture-outline",
                                                      }),
                                                    ]
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                          goods.cmasterUrl
                                            ? _c("el-image", {
                                                staticStyle: {
                                                  width: "61px",
                                                  height: "38px",
                                                },
                                                attrs: {
                                                  src: goods.cmasterUrl,
                                                  fit: "fit",
                                                },
                                              })
                                            : _vm._e(),
                                        ],
                                        1
                                      ),
                                      _c("div", { staticClass: "textCenter" }, [
                                        _vm._v("零售价"),
                                      ]),
                                      _c("div", { staticClass: "textCenter" }, [
                                        _vm._v(_vm._s(goods.nprice)),
                                      ]),
                                    ]),
                                    _c("el-col", { attrs: { span: 19 } }, [
                                      _c(
                                        "div",
                                        {},
                                        [
                                          _c(
                                            "el-link",
                                            {
                                              staticClass: "hideContent",
                                              staticStyle: {
                                                "font-weight": "800",
                                              },
                                              attrs: {
                                                title: goods.cname,
                                                type: "primary",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.handleViewGoods(
                                                    goods.sid,
                                                    goods.rsort,
                                                    _vm.sortsData
                                                  )
                                                },
                                              },
                                            },
                                            [_vm._v(_vm._s(goods.cname))]
                                          ),
                                          _c(
                                            "div",
                                            [
                                              _c("el-cascader", {
                                                staticClass:
                                                  "custom-cas-goods-list",
                                                attrs: {
                                                  value: goods.rsort,
                                                  options: _vm.sortsData,
                                                  props: {
                                                    value: "sid",
                                                    label: "ssort",
                                                  },
                                                  separator: "-",
                                                  placeholder: "暂无数据",
                                                  disabled: "",
                                                },
                                                on: {
                                                  change:
                                                    _vm.handleCascaderChange,
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "el-row",
                                            [
                                              _c("el-col", [
                                                _vm._v(
                                                  "货品SKU: " +
                                                    _vm._s(goods.cbarcode)
                                                ),
                                              ]),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "el-row",
                                            [
                                              _c("el-col", [
                                                _vm._v(
                                                  "货品条码: " +
                                                    _vm._s(goods.csku)
                                                ),
                                              ]),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-row",
                                  [
                                    _c(
                                      "el-col",
                                      [
                                        _c(
                                          "el-button-group",
                                          { staticStyle: { width: "100%" } },
                                          [
                                            _c(
                                              "el-button",
                                              {
                                                staticStyle: {
                                                  width: "50%",
                                                  color: "#1482f0",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.handleEditGoods(
                                                      goods.sid
                                                    )
                                                  },
                                                },
                                              },
                                              [_vm._v("编辑")]
                                            ),
                                            _c(
                                              "el-button",
                                              {
                                                staticStyle: { width: "50%" },
                                                attrs: { type: "danger" },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.handleDeleteGoods(
                                                      goods
                                                    )
                                                  },
                                                },
                                              },
                                              [_vm._v("删除")]
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ])
                    }),
                  ],
                  2
                ),
              ],
              1
            ),
          ]),
          _c("el-pagination", {
            staticClass: "paddingBottom20 marginRight20",
            attrs: {
              "current-page": _vm.queryParams.pageNum,
              "page-sizes": [10, 20, 30, 50],
              "page-size": _vm.queryParams.pageSize,
              layout: "total, sizes, prev, pager, next, jumper",
              total: _vm.total,
            },
            on: {
              "size-change": _vm.handleSizeChange,
              "current-change": _vm.handlePageChange,
            },
          }),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.upload.title,
            visible: _vm.upload.open,
            width: "400px",
            "append-to-body": "",
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.upload, "open", $event)
            },
          },
        },
        [
          _c(
            "el-upload",
            {
              ref: "upload",
              attrs: {
                limit: 1,
                accept: ".xlsx, .xls",
                headers: _vm.upload.headers,
                action:
                  _vm.upload.url + "?updateSupport=" + _vm.upload.updateSupport,
                disabled: _vm.upload.isUploading,
                "on-progress": _vm.handleFileUploadProgress,
                "on-success": _vm.handleFileSuccess,
                "auto-upload": false,
                drag: "",
              },
            },
            [
              _c("i", { staticClass: "el-icon-upload" }),
              _c("div", { staticClass: "el-upload__text" }, [
                _vm._v("点击上传"),
              ]),
              _c(
                "div",
                {
                  staticClass: "el-upload__tip text-center",
                  attrs: { slot: "tip" },
                  slot: "tip",
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "el-upload__tip",
                      attrs: { slot: "tip" },
                      slot: "tip",
                    },
                    [
                      _c("el-checkbox", {
                        model: {
                          value: _vm.upload.updateSupport,
                          callback: function ($$v) {
                            _vm.$set(_vm.upload, "updateSupport", $$v)
                          },
                          expression: "upload.updateSupport",
                        },
                      }),
                      _vm._v(" 是否更新已经存在的货品数据"),
                    ],
                    1
                  ),
                ]
              ),
            ]
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.submitFileForm },
                },
                [_vm._v("确 定")]
              ),
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.upload.open = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }